<template>
    <router-link :to="url">
        <div :class="['card-question-container', answer ? answer.correct ? 'correct' : 'incorrect' : '']">
            <div class="card-index">
                <span class="body-regular-14 ">{{ index }}</span>
            </div>
            <div style="width: 95%;">
                <h5 class="body-semibold-16 color-base">{{ title }}</h5>
                <p class="card-description body-regular-14">{{ decodeHtml(description) }}</p>
            </div>
            <div v-if="answer" class="card-question-container__answer">
                <IconSax type="bold" name="tick-circle" v-if="answer.correct" />
                <IconSax type="bold" name="close-circle" v-else />
                <span class="body-semibold-10">{{ answer.attempts }}x</span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "CardQuestion",
    props: {
        index: {
            type: Number,
            default: 1,
        },
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        url: {
            type: String,
            default: "",
        },
        answer: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {

        }
    },
    methods: {
        decodeHtml(htmlString) {
            let elem = document.createElement('div');
            elem.innerHTML = htmlString;
            return elem.textContent || elem.innerText;
        }
    },
};
</script>

<style lang="scss" scoped>
.correct {
    background: var(--feedback-success-100) !important;

    [data-theme="dark"] & {
        background: rgba(96, 181, 39, 0.10) !important;
    }

    ::v-deep .svgCss {
        svg path {
            fill: var(--feedback-success-500) !important;
        }
    }
}

.incorrect {
    background: var(--feedback-error-100) !important;

    [data-theme="dark"] & {
        background: rgba(255, 57, 50, 0.10) !important;
    }

    ::v-deep .svgCss {
        svg path {
            fill: var(--feedback-error-500) !important;
        }
    }
}

.card-question-container {
    @include flex-row;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    justify-content: flex-start;

    background-color: var(--neutral-gray-50);
    border: 1px solid var(--neutral-gray-200);

    [data-theme="dark"] & {
        background-color: var(--neutral-gray-800);
        border: 1px solid var(--neutral-gray-800);
    }

    width: 100%;

    .card-description {
        color: var(--fontcolor) !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 1rem;
    }

    .card-index {
        color: var(--neutral-gray-700);

        [data-theme="dark"] & {
            color: var(--neutral-gray-500);
        }

        margin-top: -1.7px !important;
    }

    &:hover {
        border: 1px solid var(--maincolor);
        cursor: pointer;
    }

    &__answer {
        @include flex-column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        ::v-deep .svgCss {
            svg {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

        span {
            display: flex;
            width: 1rem;
            height: 1rem;
            padding: 0.5rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0.25rem;
            background: var(--neutral-gray-100, #E2E2E2);
            color: var(--neutral-black);
        }

    }
}
</style>